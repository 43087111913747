import script from "./ModalRoot.vue?vue&type=script&setup=true&lang=js"
export * from "./ModalRoot.vue?vue&type=script&setup=true&lang=js"

import "./ModalRoot.vue?vue&type=style&index=0&id=288003fa&lang=css"

const __exports__ = script;

export default __exports__
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QBtn});
