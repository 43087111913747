import "quasar/dist/quasar.css";
import "@quasar/extras/material-icons/material-icons.css";
import Dialog from 'quasar/src/plugins/Dialog.js';;
import langRu from "quasar/lang/ru.mjs";

export default {
  config: {
  },
  plugins: {
    Dialog,
  },
  lang: langRu,
};
